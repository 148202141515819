import { Link, Outlet, Route, Routes } from "react-router-dom";

import PageBeastMode from "./page/BeastMode";
import PageHome from "./page/Home";
import PageLootshop from "./page/Lootshop";
import PageNotFound from "./page/NotFound";

import "./App.css";

function App() {
  return (
    <main>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<PageHome />} />
          <Route path="lootshop" element={<PageLootshop />} />
          <Route path="beastmode" element={<PageBeastMode />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </main>
  );
}

function Layout() {
  return (
    <div>
      <nav>
        <ul>
          <li>
            <Link to="/">Manyhats v0.1.1</Link>
          </li>

          <li>
            <Link to="/lootshop">Lootshop Weapons</Link>
          </li>

          <li>
            <Link to="/beastmode">Beast Mode Playground</Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </div>
  );
}

export default App;
