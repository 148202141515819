import { ethers } from "ethers";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactTabulator } from "react-tabulator";

import "react-tabulator/lib/css/tabulator.min.css";
import "react-tabulator/lib/styles.css";

class WeaponAttribute {
  display_type = "";
  trait_type = "";
  value = null;
  max_value = null;

  constructor(data) {
    Object.assign(this, data);
  }
}

class Weapon {
  name = "";
  type = "";
  speed = "";
  damage = "";
  quality = "";
  full_name = "";
  affinity = "";
  affinity_strength = "";
  element = "";
  element_strength = "";
  element_type = "";
  level = "";
  metaverse_xp = "";
  raw_xp = "";
  enchantments = "";
  description = "";
  edition = 0;
  image = "";
  attributes = [];

  constructor(data) {
    this.name = data.name;
    this.description = data.description;
    this.edition = data.edition;
    this.image = data.image;
    this.attributes = Array.isArray(data.attributes)
      ? data.attributes.map((x) => new WeaponAttribute(x))
      : [];
    this.type =
      this.attributes.find((x) => x.trait_type === "Weapon Type")?.value ||
      "NA";
    this.speed =
      this.attributes.find((x) => x.trait_type === "Speed")?.value || "NA";
    this.damage =
      this.attributes.find((x) => x.trait_type === "Damage")?.value || "NA";
    this.quality =
      this.attributes.find((x) => x.trait_type === "Quality")?.value || "NA";
    this.full_name =
      this.attributes.find((x) => x.trait_type === "Full Name")?.value || "NA";
    this.affinity =
      this.attributes.find((x) => x.trait_type === "Affinity")?.value || "NA";
    this.affinity_strength =
      this.attributes.find((x) => x.trait_type === "Affinity Strength")
        ?.value || "NA";
    this.element =
      this.attributes.find((x) => x.trait_type === "Element")?.value || "NA";
    this.element_strength =
      this.attributes.find((x) => x.trait_type === "Element Strength")?.value ||
      "NA";
    this.element_type =
      this.attributes.find((x) => x.trait_type === "Element Type")?.value ||
      "NA";
    this.level = this.getDisplayType("Level");
    this.metaverse_xp = this.getDisplayType("Metaverse XP");
    this.raw_xp = this.getDisplayType("Raw XP");
    this.enchantments = this.getEnchantments();
  }

  getDisplayType(_trait_type) {
    let res = "";
    let value = this.attributes.find((x) => x.trait_type === _trait_type);

    do {
      if (!value) {
        res = "NA";
        break;
      }

      res = `${value?.value} - ${value?.max_value}`;
    } while (0);

    return res;
  }

  getEnchantments() {
    let res = "";
    let values = this.attributes.filter((x) => x.trait_type === "Enchantment");

    do {
      if (values.length === 0) {
        res = "NA";
        break;
      }

      for (const v of values) {
        if (res === "") {
          res = v?.value;
        } else {
          res += `, ${v?.value}`;
        }
      }
    } while (0);

    return res;
  }
}

async function Delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function GetStorageValue(key) {
  const value = localStorage.getItem(key);

  switch (key) {
    case STORAGE_KEY_WEAPON_IDS:
      let res = [];

      try {
        if (value !== null) {
          res = [...value.split(",")];
        }
      } catch (error) {
        console.error(error);
      }

      return res;

    default:
      return typeof value === "string" ? value : "";
  }
}

async function GetWeaponsMeta(weapon_ids, set_current_token_id) {
  let res_weapons = [];

  for (const weapon_id of weapon_ids) {
    set_current_token_id(weapon_id);
    await Delay(50); // allow 20 messages per second
    try {
      let res = await fetch(LOOTSHOP_CONTRACT_TOKEN_URI_BASE + weapon_id);
      let data = await res.json();
      let weapon = new Weapon(data);

      localStorage.setItem(
        STORAGE_KEY_TOKEN_ID_BASE + weapon_id,
        JSON.stringify(data)
      );
      res_weapons.push(weapon);
    } catch (error) {
      console.error(error);
    }
  }

  localStorage.setItem(STORAGE_KEY_LAST_SYNC, new Date().toTimeString());

  return res_weapons;
}

function PageLootshop() {
  const refTable = useRef();
  const [wallet_address, set_wallet_address] = useState(
    GetStorageValue(STORAGE_KEY_WALLET_ADDRESS)
  );
  const [weapon_ids, set_weapon_ids] = useState(
    GetStorageValue(STORAGE_KEY_WEAPON_IDS)
  );
  const [last_sync, set_last_sync] = useState(
    GetStorageValue(STORAGE_KEY_LAST_SYNC)
  );
  const [weapon_ids_loaded, set_weapon_ids_loaded] = useState(false);
  const [weapons, set_weapons] = useState([]);
  const [weapons_loaded, set_weapons_loaded] = useState(false);
  const [download_clicked, set_download_clicked] = useState(false);

  const changeWallet = () => {
    localStorage.clear();
    set_weapons([]);
    set_weapons_loaded(false);
    set_last_sync("");
    set_weapon_ids([]);
    set_weapon_ids_loaded(false);
    set_wallet_address("");
    set_download_clicked(false);
  };

  const resync = () => {
    localStorage.clear();
    localStorage.setItem(STORAGE_KEY_WALLET_ADDRESS, wallet_address);
    set_weapons([]);
    set_weapons_loaded(false);
    set_last_sync("");
    set_weapon_ids([]);
    set_weapon_ids_loaded(false);
    set_download_clicked(false);
  };

  const downloadData = () => {
    if (refTable.current) {
      set_download_clicked(true);
      refTable.current.current.download("csv", "lootshop-weapons.csv");
      setTimeout(() => {
        set_download_clicked(false);
      }, 420);
    }
  };

  if (!wallet_address) {
    return <WalletAddressInput set_wallet_address={set_wallet_address} />;
  }

  if (!weapon_ids_loaded) {
    return (
      <LoadingWeaponIds
        weapon_ids_loaded={weapon_ids_loaded}
        set_weapon_ids={set_weapon_ids}
        set_weapon_ids_loaded={set_weapon_ids_loaded}
        wallet_address={wallet_address}
        changeWallet={changeWallet}
      />
    );
  }

  if (!weapons_loaded) {
    return (
      <LoadingWeapons
        weapons_loaded={weapons_loaded}
        set_weapons={set_weapons}
        set_weapons_loaded={set_weapons_loaded}
        last_sync={last_sync}
        wallet_address={wallet_address}
        changeWallet={changeWallet}
        weapon_ids={weapon_ids}
      />
    );
  }

  return (
    <div>
      <Header wallet_address={wallet_address} weapon_ids={weapon_ids} />

      <ChangeWalletButton changeWallet={changeWallet} />

      <div>
        <button
          type="button"
          onClick={downloadData}
          disabled={download_clicked}
        >
          Download CSV
        </button>

        <button type="button" onClick={resync}>
          Resync
        </button>
      </div>

      <ReactTabulator
        onRef={(ref) => (refTable.current = ref)}
        data={weapons}
        columns={COLUMNS}
      />
    </div>
  );
}

function ChangeWalletButton({ changeWallet }) {
  return (
    <div>
      <button type="button" onClick={changeWallet}>
        Change Wallet
      </button>
    </div>
  );
}

function Header({ wallet_address, weapon_ids }) {
  return (
    <div>
      <h1>Lootshop Weapons - Series 1</h1>

      {wallet_address ? (
        <h4>
          Wallet Address:{" "}
          <span style={{ color: "grey" }}>{wallet_address}</span>
        </h4>
      ) : null}

      {weapon_ids ? (
        <h4>
          Weapons Count:{" "}
          <span style={{ color: "grey" }}>{weapon_ids.length}</span>
        </h4>
      ) : null}
    </div>
  );
}

function WalletAddressInput({ set_wallet_address }) {
  const [value, set_value] = useState("");

  const onChange = (e) => set_value(e.target.value);

  const onNext = () => {
    localStorage.setItem(STORAGE_KEY_WALLET_ADDRESS, value);
    set_wallet_address(value);
  };

  return (
    <div>
      <Header />

      <h3>No ETH Wallet found in Local Storage</h3>

      <p>
        Please put in your ETH wallet address below and click <b>Next</b> to get
        the latest meta for your weapons.
      </p>

      <h5 style={{ color: "red" }}>
        Important{" "}
        <span style={{ color: "grey", textDecoration: "italic" }}>
          This is READ ONLY so you will NEVER be asked for a signature or
          anything.
        </span>{" "}
      </h5>

      <div>
        <p>
          <label htmlFor="wallet_address">
            Wallet ETH Address (ENS names are fine)
          </label>

          <br />

          <input
            id="wallet_address"
            style={{ width: "320px" }}
            value={value}
            onChange={onChange}
          />
        </p>

        <p>
          <button disabled={!value.length} onClick={onNext}>
            Next
          </button>
        </p>
      </div>
    </div>
  );
}

function LoadingWeapons({
  weapons_loaded,
  set_weapons_loaded,
  set_weapons,
  last_sync,
  weapon_ids,
  wallet_address,
  changeWallet,
}) {
  const [msg, set_msg] = useState("");
  const [current_token_id, set_current_token_id] = useState("");

  useEffect(() => {
    if (!weapons_loaded) {
      console.log("loading weapons");

      let msg = last_sync
        ? `Last synced: ${last_sync}`
        : `Please be patient as we download the meta data for your ${weapon_ids.length} weapons. We don't want to overkill our friends!`;

      console.log(msg);
      set_msg(msg);

      do {
        if (last_sync) {
          const _weapons = weapon_ids
            .map((weapon_id) => {
              const weapon_string = GetStorageValue(
                STORAGE_KEY_TOKEN_ID_BASE + weapon_id
              );

              if (weapon_string === "") {
                return weapon_string;
              }

              try {
                return JSON.parse(weapon_string);
              } catch (error) {
                console.error(error);

                return "";
              }
            })
            .map((x) => new Weapon(x));

          set_weapons(_weapons);
          set_weapons_loaded(true);
          break;
        }

        GetWeaponsMeta(weapon_ids, set_current_token_id)
          .then((data) => {
            set_weapons(data);
            set_weapons_loaded(true);
          })
          .catch((e) => {
            console.error(e);
            set_msg(e.message);
          });
      } while (0);
    }
  }, [weapons_loaded, last_sync, weapon_ids, set_weapons, set_weapons_loaded]);

  return (
    <div>
      <Header wallet_address={wallet_address} />

      <p>Loading Weapons</p>

      {msg ? (
        <div>
          <p>{msg}</p>
          <ChangeWalletButton changeWallet={changeWallet} />
        </div>
      ) : null}

      {current_token_id ? (
        <p>Currently working on Weapon ID {current_token_id}</p>
      ) : null}

      <div className="lds-ripple">
        <div style={{ backgroundColor: "darkgray" }}></div>
        <div></div>
      </div>
    </div>
  );
}

function LoadingWeaponIds({
  weapon_ids_loaded,
  set_weapon_ids,
  set_weapon_ids_loaded,
  wallet_address,
  changeWallet,
}) {
  const [msg, set_msg] = useState("");

  const getWeaponIds = useCallback(async () => {
    const provider = new ethers.providers.Web3Provider(window?.ethereum);
    const contract = new ethers.Contract(
      LOOTSHOP_CONTRACT_ADDRESS,
      LOOTSHOP_CONTRACT_ABI,
      provider
    );

    let result = await contract.walletOfOwner(wallet_address);
    let res = [];

    for (const weapon_id of result) {
      const token_id = weapon_id.toNumber();

      res.push(token_id);
    }

    return res;
  }, [wallet_address]);

  useEffect(() => {
    if (!weapon_ids_loaded) {
      console.log("loading weapon ids");

      let ids = GetStorageValue(STORAGE_KEY_WEAPON_IDS);
      let msg = `${ids.length} weapon IDs found in local storage.${
        ids.length === 0
          ? " Checking wallet address in the Lootshop contract on the blockchain"
          : ""
      }`;

      console.log(msg);
      set_msg(msg);

      do {
        if (ids.length !== 0) {
          set_weapon_ids(ids);
          set_weapon_ids_loaded(true);
          break;
        }

        getWeaponIds()
          .then((weapon_ids) => {
            localStorage.setItem(STORAGE_KEY_WEAPON_IDS, weapon_ids.join(","));
            set_weapon_ids(weapon_ids);
            set_weapon_ids_loaded(true);
          })
          .catch((e) => {
            console.error(e);
            set_msg(e.message);
          });
      } while (0);
    }
  }, [weapon_ids_loaded, getWeaponIds, set_weapon_ids, set_weapon_ids_loaded]);

  return (
    <div>
      <Header wallet_address={wallet_address} />

      <p>Loading Weapon IDs</p>

      {msg ? (
        <div>
          <p>{msg}</p>
          <ChangeWalletButton changeWallet={changeWallet} />
        </div>
      ) : null}

      <div className="lds-ripple">
        <div style={{ backgroundColor: "darkgray" }}></div>
        <div></div>
      </div>
    </div>
  );
}

const STORAGE_KEY_WALLET_ADDRESS = "wallet_address_lootshop";
const STORAGE_KEY_WEAPON_IDS = "weapon_ids_lootshop";
const STORAGE_KEY_LAST_SYNC = "last_sync_lootshop";
const STORAGE_KEY_TOKEN_ID_BASE = "token_id_lootshop_";
const LOOTSHOP_CONTRACT_ADDRESS = "0x402CdBD1c4aBaB725B6970812bEe1B08AC864854";
const LOOTSHOP_CONTRACT_TOKEN_URI_BASE =
  "https://token.nftscribe.io/p/d53313d5-a532-41ad-8c4d-a5637cb2d128/d/";
const LOOTSHOP_CONTRACT_ABI = [
  {
    inputs: [{ internalType: "address", name: "_owner", type: "address" }],
    name: "walletOfOwner",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
];
const COLUMNS = [
  { title: "Token ID", field: "edition", headerFilter: true },
  { title: "Name", field: "name", headerFilter: true },
  { title: "Type", field: "type", headerFilter: true },
  { title: "Speed", field: "speed", headerFilter: true },
  { title: "Damage", field: "damage", headerFilter: true },
  { title: "Quality", field: "quality", headerFilter: true },
  { title: "Full Name", field: "full_name", headerFilter: true },
  { title: "Affinity", field: "affinity", headerFilter: true },
  {
    title: "Affinity Strength",
    field: "affinity_strength",
    headerFilter: true,
  },
  { title: "Element", field: "element", headerFilter: true },
  { title: "Element Type", field: "element_type", headerFilter: true },
  {
    title: "Element Strength",
    field: "element_strength",
    headerFilter: true,
  },
  { title: "Level", field: "level", headerFilter: true },
  { title: "Metaverse XP", field: "metaverse_xp", headerFilter: true },
  { title: "Raw XP", field: "raw_xp", headerFilter: true },
  { title: "Enchantments", field: "enchantments", headerFilter: true },
];

export default PageLootshop;
