function PageHome() {
  return (
    <div>
      <h2>Home</h2>

      <p>Just an empty shell for now, check links above for tests</p>
    </div>
  );
}

export default PageHome;
