import { useEffect } from "react";

function PageBeastMode() {
  useEffect(() => {
    const init = async () => {
      console.log("coming soon");
    };

    init()
      .then(() => {
        console.log("hope");
      })
      .catch((e) => {
        console.error({ e });
      });
  }, []);
  return (
    <div>
      <h2>Beast Mode Playground</h2>

      <p>TODO test signing into Hive Engine</p>
    </div>
  );
}

export default PageBeastMode;
